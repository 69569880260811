// @flow
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_DATA = "USER_DATA";
export const SET_PARTNER_STATUS = "SET_PARTNER_STATUS";
export const SET_PARTNER_SETTINGS = "SET_PARTNER_SETTINGS";
export const IS_CONFIG_APPLIED = "IS_CONFIG_APPLIED";

export const SET_SELECT_COLOR = "SET_SELECT_COLOR";
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const UPDATE_COMPARE_PACKAGES = 'UPDATE_COMPARE_PACKAGES'
export const SET_INSURANCE_ID = 'SET_INSURANCE_ID'
export const DELETE_INSURANCE_ID = 'DELETE_INSURANCE_ID'
export const SET_INSURANCE_PRODUCT = 'SET_INSURANCE_PRODUCT'
export const SET_ALL_PACKAGES = 'SET_ALL_PACKAGES'
export const SET_RECOMMENDED_PACKAGES = 'SET_RECOMMENDEDPACKAGES'
export const SET_CAR_RECOMMENDED_PACKAGES = 'SET_CAR_RECOMMENDED_PACKAGES'
export const SET_CAR_NON_RECOMMENDED_PACKAGES = 'SET_CAR_NON_RECOMMENDED_PACKAGES'
export const SET_CAR_PACKAGES="SET_CAR_PACKAGES"
export const SET_NON_RECOMMENDED_PACKAGES = 'SET_NON_RECOMMENDED_PACKAGES'
export const SET_FILTER_PACKAGES = 'SET_FILTER_PACKAGES'
export const SORT_BY = 'SORT_BY'
export const FILTER_BY = 'FILTER_BY'
export const SET_FILTER_STATUS = 'SET_FILTER_STATUS'
export const SET_COMPARE_PACKAGES = 'SET_COMPARE_PACKAGES'
export const SET_CONFIG_DATA = 'SET_CONFIG_DATA'
export const SET_PARTNER_FORM_DATA='SET_PARTNER_FORM_DATA'

