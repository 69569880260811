import React from 'react'
import { Tooltip, Icon } from 'antd'
import './index.less'

const TooltipComponent = ({ tooltipText }: any) => {
  return (
    <div className="tool-tip-div">
      <Tooltip
        className="tool-tip"
        title={tooltipText}
        trigger="click"
        placement={window.innerWidth < 960 ? 'top' : 'right'}
        overlayStyle={{
          minWidth: window.innerWidth > 960 ? '450px' : '220px'
        }}>
        <Icon className="tool-tip-icon" theme="filled" type="question-circle" />
      </Tooltip>
    </div>
  )
}
export default TooltipComponent