import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, useLocation } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { CustomerlyProvider } from "react-live-chat-customerly";
import Smartlook from "smartlook-client";
// import Intercom from "react-intercom";

import AuthRoute from "../../components/auth-route";
import routes from "../../routes";

import { titleManager } from "~utils/titleManager";

import PublicRoutes from "~components/public-routes";
import { Spinner } from "~components/spinner";
// import HelpCrunch from "~components/helpcrunch";
import CustomerlyChat from "~components/customerly";

import "react-notifications-component/dist/theme.css";
import "./index.less";

function App() {
  const location = useLocation();
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const state = useSelector((state: any) => state);
  const userData = {
    email: state?.userData?.personEmail,
    name: state?.userData?.personName,
    user_id: state?.userData?.userId,
  };

  /* ---------------------------------- HOOKS --------------------------------- */
  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    if (env == "production") {
      const smartLookKey = process.env.REACT_APP_SMARTLOOK_KEY;
      const smartLookRegion = process.env.REACT_APP_SMARTLOOK_REGION;
      Smartlook.init(smartLookKey, {
        region: smartLookRegion,
      });
    }
  }, []);
  useEffect(() => {
    titleManager(location);
  }, [location]);

  // const intercomId = process.env.REACT_APP_INTERCOM_APP_ID;
  const customerlyId = process.env.REACT_APP_CUSTOMERLY_ID;
  return (
    <React.Fragment>
      <ReactNotification />
      <Suspense fallback={<Spinner />}>
        <Switch>
          {!isAuthenticated ? (
            <Redirect exact from="/" to="/entrar" />
          ) : (
            <Redirect exact from="/" to="/dashboard" />
          )}
          {routes.map((route: Object, index: number) => {
            if (route.authRequired) {
              return (
                <AuthRoute
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  name={route.name}
                  path={route.path}
                  title={route.title}
                  notAccess={route.notAccess}
                  {...route}
                />
              );
            } else {
              return (
                <PublicRoutes
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  name={route.name}
                  path={route.path}
                  title={route.title}
                  {...route}
                />
              );
            }
          })}
        </Switch>
      </Suspense>
      {location.pathname != "/iframe-redirect-success" && isAuthenticated && (
        // <Intercom appID={intercomId} />
        // <HelpCrunch userData={userData} />
        <CustomerlyProvider appId={customerlyId}>
          <CustomerlyChat userData={userData} />
        </CustomerlyProvider>
      )}
    </React.Fragment>
  );
}
export default App;
