import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import mudeyGreyIcon from "~images/mudeyGreyNew.png";
import downArrow from "~images/downArrow.svg";
import upArrow from "~images/upArrow.svg";
import "./index.less";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getPartner } from "./../../hooks/useUserType";

const NewFooter = ({ customCSS, customFooter }: any) => {
  const isAuthenticated = useSelector((state: any) => state?.isAuthenticated);
  const configData = useSelector((state) => state?.configData);
  const partnershipType = useSelector(
    (state) => state?.partnerFormDetails?.partnershipType
  );
  const ref = useRef();
  const { t } = useTranslation();
  const location = useLocation();

  const [footerHeight, setFooterHeight] = useState(0);
  const getTranslateText = {
    LIFE: "vida",
    NON_LIFE: "não-vida",
    BOTH: "vida e não-vida",
  };
  const [show, setShow] = useState(false);
  const footerText =
    "A MUDEY é uma mediadora de seguros autorizada pela ASF com o número 420558967, no ramo vida e não-vida.";
  const customFooterText = `A MUDEY é uma mediadora de seguros autorizada pela ASF com o nº 420558967, no ramo vida e não-vida. Os contratos são celebrados em regime de co-mediação com o mediador ${_.get(
    configData,
    "name",
    null
  )} autorizado com o nº ${_.get(configData, "asfNumber", null)}, no ramo ${
    getTranslateText[_.get(configData, "authorized", null)]
  }`;
  const hyperlinks = [
    {
      label: "Politica de Privacidade",
      href: "https://mudey.pt/politica-de-privacidade/",
    },
    {
      label: "Política de Cookies",
      href: "https://mudey.pt/politica-cookies/",
    },
    {
      label: "Termos de Uso",
      href: "https://mudey.pt/termos-de-utilizacao/",
    },
    {
      label: "Política de Tratamento",
      href: "https://mudey.pt/politica-tratamento/",
    },
    {
      label: "Aviso de Privacidade",
      href: "https://mudey.pt/aviso-privacidade/",
    },
    {
      label: "Regulamento sobre Pagamentos",
      href: "https://mudey.pt/politica-pagamentos/",
    },
    {
      label: "Gestão de Reclamações",
      href: "https://mudey.pt/gestao-de-reclamacoes/",
    },
    {
      label: "Deveres Especiais de Informação",
      href:
        "https://mudey.pt/wp-content/uploads/2022/11/Ficha-Informativa-V10.pdf",
    },
  ];

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      if (!show) {
        setTimeout(() => {
          setShow(true);
        }, 300);
      }
    }

    const contentBlockFooter = document.querySelector(".content-block-footer");
    if (contentBlockFooter) {
      setFooterHeight(show ? contentBlockFooter.clientHeight : 0);
    }
  }, [show]);

  const getFooterText = () => {
    if (customFooter && partnershipType !== "PDEAMS") {
      return customFooterText;
    } else if (partnershipType == "PDEAMS") {
      return footerText;
    } else {
      return footerText;
    }
  };

  return getPartner() === "MUDEY" ? (
    <footer
      className={`footer ${show ? "footer-increased" : ""}`}
      style={{
        height:
          (location.pathname == "/seguro-viagem/resultados/pacotes" ||
            location.pathname == "/seguro-carro/resultados/pacotes/" ||
            location.pathname == "/seguro-carro/resultados/pacotes" ||
            location.pathname == "/seguro-saude/resultados/pacotes") &&
          isAuthenticated
            ? show
              ? "260px"
              : "180px"
            : "",
      }}
    >
      <div className="content-block-footer container">
        <div className="footer-wrapper">
          <div className="footer-wrapper-iob">
            <img
              alt="Mudey Logo"
              className="footer-logo"
              src={mudeyGreyIcon}
              title="Mudey Logo"
            />
            <span>
              &copy; {new Date().getFullYear()} MUDEY&nbsp;
              {t("footer.rights")}
            </span>
            {/* <img alt="Flag Icon" className="footer-flag" src={portugalFlagIcon} /> */}
            {/* <span>{t('footer.created')}</span> */}
          </div>

          <div className="footer-wrapper-col">
            <p style={{ textAlign: "center" }}>
              {getFooterText()}
              {/* {customFooter ? customFooterText : footerText} */}
            </p>
          </div>
          <p className="main-text" onClick={() => setShow(!show)}>
            Informação Legal
            {!show ? (
              <img src={downArrow} className="arrow-img-down" />
            ) : (
              <img src={upArrow} className="arrow-img-up" />
            )}
          </p>
          {show && (
            <>
              <div className="hyperlinks-wrapper">
                {hyperlinks.map((link) => (
                  <span key={link.href}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.href}
                    >
                      {link.label}
                    </a>
                  </span>
                ))}
              </div>
              <div className="hyperlinks-end-div" ref={ref} />
            </>
          )}
        </div>
      </div>
    </footer>
  ) : (
    <footer
      className={`footer ${show ? "footer-increased" : ""}`}
      style={{
        height:
          (location.pathname == "/seguro-viagem/resultados/pacotes" ||
            location.pathname == "/seguro-carro/resultados/pacotes/" ||
            location.pathname == "/seguro-carro/resultados/pacotes" ||
            location.pathname == "/seguro-saude/resultados/pacotes") &&
          isAuthenticated
            ? show
              ? "260px"
              : "180px"
            : "",
      }}
    >
      <div className="content-block-footer container">
        <div className="footer-wrapper">
          <div className="footer-wrapper-iob">
            <span>
              &copy; {new Date().getFullYear()} Costa Duarte. Todos os direitos reservados. &nbsp;
            </span>
          </div>

          <div className="footer-wrapper-col">
            <p style={{ textAlign: "center" }}>
              Costa Duarte - Corretor de Seguros, S.A. NIPC/NIF 503976555. Capital Social: €123.000,00. Mediador de seguros inscrito em 27-01-2007 no registo da ASF - Autoridade de Supervisão de Seguros e Fundos de Pensões, com a categoria de Corretor de Seguros, sob o nº 607084984/3, com autorização para os Ramos Vida e Não Vida, verificável em www.asf.com.pt.
            </p>
          </div>

          {show && (
            <>
              <div className="hyperlinks-wrapper">
                {hyperlinks.map((link) => (
                  <span key={link.href}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.href}
                    >
                      {link.label}
                    </a>
                  </span>
                ))}
              </div>
              <div className="hyperlinks-end-div" ref={ref} />
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
