// @flow
import Cookies from "js-cookie";
import * as actionTypes from "./actions";
import mudeyDefault from "~images/mudeyPro_default.png";
import { auth } from "../components/firebase/firebase";
import { SET_CAR_NON_RECOMMENDED_PACKAGES } from "./constant";

const initialState = {
  userData: null,
  token: null,
  isAuthenticated: false,
  isPartnerVerified: null,
  isConfigApplied: false,
  partnerSettings: {
    logo: mudeyDefault,
    colors: {
      primary: "#895d85",
      secondary: "#aacecd",
    },
  },
  configData: null,
  recommendedPackages: null,
  nonRecommendedPackages: null,
  allPackages: null,
  filterPackages: null,
  filterStatus: null,
  sortBy: null,
  filterBy: null,
  comparePackages: [],
  partnerFormDetails: null,
};

const reducer = (state: Object = initialState, action: Object) => {
  const { payload, type } = action;
  switch (type) {
    case actionTypes.SET_PARTNER_STATUS:
      return {
        ...state,
        isPartnerVerified: payload,
      };
    case actionTypes.IS_CONFIG_APPLIED:
      return {
        ...state,
        isConfigApplied: payload,
      };
    case actionTypes.SET_CONFIG_DATA:
      return {
        ...state,
        configData: payload,
      };
    case actionTypes.SET_PARTNER_SETTINGS:
      return {
        ...state,
        partnerSettings: {
          logo: payload,
          // colors: {
          //   primary: payload?.colors?.primary,
          //   secondary: payload?.colors?.secondary,
          //   // tertiary: payload?.colors?.teritiary,
          // },
        },
      };
    case actionTypes.UPDATE_COMPARE_PACKAGES:
      return {
        ...state,
        journeyId: payload.journeyId,
        comparePackages: payload.packages,
        slug: payload.slug,
      };
    case actionTypes.SET_RECOMMENDED_PACKAGES:
      return {
        ...state,
        recommendedPackages: action.payload,
      };
    case actionTypes.SET_NON_RECOMMENDED_PACKAGES:
      return { ...state, nonRecommendedPackages: action.payload };
    case actionTypes.SET_ALL_PACKAGES:
      return { ...state, allPackages: action.payload };
    case actionTypes.SET_FILTER_PACKAGES:
      return { ...state, filterPackages: action.payload };
    case actionTypes.SET_FILTER_STATUS:
      return { ...state, filterStatus: action.payload };
    case actionTypes.SORT_BY:
      return { ...state, sortBy: action.payload };
    case actionTypes.FILTER_BY:
      return { ...state, filterBy: action.payload };
    case actionTypes.SET_COMPARE_PACKAGES:
      return { ...state, comparePackages: action.payload };
    case actionTypes.LOGIN:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case actionTypes.USER_DATA:
      return {
        ...state,
        userData: payload,
      };
    case actionTypes.SET_PARTNER_FORM_DATA:
      return {
        ...state,
        partnerFormDetails: payload,
      };

    case SET_CAR_NON_RECOMMENDED_PACKAGES:
      return { ...state, car_nonrecommended_packages: action.payload };
    case actionTypes.LOGOUT:
      auth.signOut();
      // persistor.purge();
      Cookies.remove("Mudey_partner_token");
      Cookies.remove("MUDEY_wallet_token");
      Cookies.remove("MUDEY_wallet_token");
      Cookies.remove("MUDEY_uuid");
      Cookies.remove("MUDEY_email");
      Cookies.remove("MUDEY_name");
      Cookies.remove("MUDEY_wallet_uuid");
      Cookies.remove("MUDEY_wallet_email");
      Cookies.remove("MUDEY_wallet_name");
      Cookies.remove("MUDEY_hard_expiration");
      window.location.reload()
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
