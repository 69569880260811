// @flow
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { createBrowserHistory } from "history";

import TagManager from "react-gtm-module";

import { persistor, store } from "./store";

import ScrollToTop from "./ScrollToTop";
import App from "./containers/app";

import { ApolloProvider } from "@apollo/react-hooks";
import client from "./services/api";

import _ from 'lodash'
import "./i18n";
import "sanitize.css/sanitize.css";
import "./resources/less/index.less";

if (process.env.REACT_APP_GTM_KEY.length != 0) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

export const history = createBrowserHistory();

const target = document.querySelector("#root");

if (target) {
  render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <Router history={history}>
            <ScrollToTop>
              <App client={client} />
            </ScrollToTop>
          </Router>
        </ApolloProvider>
      </PersistGate>
    </Provider>,
    target
  );
}
