import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import mediaBreakPoint from "~utils/media-breakpoint";
import { Steps } from "antd";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { apiErrorHandler } from "~utils/apiErrorHandler";
import "./index.less";
import { ADD_ADVISOR_ANSWERS } from "../../containers/advisor/utils/queries";
import { useSelector } from "react-redux";

const HeaderTitle = ({
  title,
  paraTitle,
  paraTitle2,
  backButtonText,
  backButtonClick,
  children,
  style,
  rightButton,
  rightBtnText,
  showSteps,
  rightBtnOnClick,
  rightBtnStyle,
  paraTitleFirstStyle,
  paraTitleSecondStyle,
  paraTitleSpanStyle,
  paraTitle3,
  paraTitleThirdStyle,
  disableRightBtn
}: any) => {
  const nif = useSelector((state: any) => state?.userData?.nif);
  const history = useHistory();
  const renderSteps = () => {
    const { pathname } = location;
    let step = -1;

    if (pathname.indexOf("/formulario-adesao") > -1) step = 0;
    else if (
      pathname.indexOf("/formulário-plano") > -1 ||
      pathname.indexOf("/formulário-plano") > -1
    )
      step = 1;
    else if (
      pathname.indexOf("/formulario-assinatura") > -1 ||
      pathname.indexOf("/formulario-assinatura") > -1
    )
      step = 2;

    return step >= 0 ? (
      <Steps current={step} progressDot size="small">
        <Steps.Step title="Adesão" />
        <Steps.Step title="Plano" />
        <Steps.Step title="Assinatura" />
      </Steps>
    ) : (
      <div />
    );
  };

  const handleAddAdvisor = () => {
    addAdvisor({
      variables: {
        partnerNif: nif,
        responses: [
          { answer: "", questionId: "64cd57ce-a9b0-42ba-850b-34e7366d0155" },
        ],
      },
    });
  };
  const [addAdvisor] = useMutation(ADD_ADVISOR_ANSWERS, {
    onCompleted: ({ addAdvisorAnswersPublic }: any) => {
      window.open(
        `${process.env.REACT_APP_HP_URL}venda-cruzada/questionario?formId=${addAdvisorAnswersPublic.uuid}`,
        "_blank"
      );
      // window.open(
      //   `http://localhost:3002/venda-cruzada/questionario?formId=${addAdvisorAnswersPublic.uuid}`,
      //   "_blank"
      // );
    },
    onError: (err: any) => {
      apiErrorHandler(err);
    },
  });
  return (
    <div className="header-container-main">
      {backButtonText && (
        <div className="back-button">
          <span onClick={backButtonClick} className="back-button-text">
            {backButtonText}
          </span>
        </div>
      )}
      <div className="title-wrapper" style={style}>
        <div className="title-left">
          <div
            className="title-header"
            style={{
              color: "black",
              display: "flex",
              justifyContent:
                location.pathname == "/formul%C3%A1rio-plano"
                  ? "space-between"
                  : "",
              alignItems:
                location.pathname == "/formul%C3%A1rio-plano" ? "start" : "",
            }}
          >
            {title}
            {location.pathname == "/formul%C3%A1rio-plano" && (
              <span className="offer-plan">Oferta do Plano Base durante o período experimental</span>
            )}
          </div>
          {paraTitle && (
            <div className="para-title-header" style={paraTitleFirstStyle}>
              <span style={paraTitleSpanStyle}>{paraTitle}</span>
            </div>
          )}
          {paraTitle2 && (
            <div className="para-title-header" style={paraTitleSecondStyle}>
              <span>{paraTitle2}</span>
            </div>
          )}
          {paraTitle3 && (
            <div className="para-title-header" style={paraTitleThirdStyle}>
              <span>{paraTitle3}</span>
            </div>
          )}
        </div>
        {rightButton && (
          <div>
            <button
              className="button-enabled"
              style={rightBtnStyle}
              onClick={rightBtnOnClick}
              disabled={disableRightBtn}
            >
              {rightBtnText}
            </button>
          </div>
        )}
        {showSteps && <div className="title-right">{renderSteps()}</div>}
      </div>
      {children}
    </div>
  );
};

export default HeaderTitle;
