import { createStore,applyMiddleware,combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import reducer from './reducer'

const persistConfig = {
  key: 'partner-wallet',
  storage: storage
}

const pReducer = persistReducer(persistConfig, reducer)

// export const store = createStore(pReducer)
export const store=createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)))

export const persistor = persistStore(store)

