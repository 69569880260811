import React from "react";
import { Spin } from "antd";
import styles from "./index.module.css";

export const Spinner = ({ opacity, position }: any) => {
  return (
    <div
      className={styles.spinner}
      style={{ opacity: opacity || "", position: position || "fixed" }}
    >
      <div className={styles["spinner-wrapper"]}>
        <Spin size="large" className={styles["spinner-login"]} />
      </div>
    </div>
  );
};
