import { gql } from "@apollo/client";

export const ADD_ADVISOR_ANSWERS = gql`
  mutation addAdvisorAnswersPublic(
    $responses: [AnswerResponseInput]
    $partnerNif: String
  ) {
    addAdvisorAnswersPublic(
      input: { responses: $responses, partnerNif: $partnerNif }
    ) {
      uuid
    }
  }
`;
export const UPDATE_ADVISOR_ANSWERS = gql`
  mutation updateAdvisorAnswersPublic(
    $uuid: String
    $responses: [AnswerResponseInput]
    $partnerNif: String
    $customerNif: String
  ) {
    updateAdvisorAnswersPublic(
      input: {
        uuid: $uuid
        responses: $responses
        partnerNif: $partnerNif
        customerNif: $customerNif
      }
    ) {
      uuid
    }
  }
`;
export const GET_ADVISOR_SUGGESTIONS = gql`
  query getAdvisorPartnerSuggestions($userAnswersUuid: String!) {
    getAdvisorPartnerSuggestions(userAnswersUuid: $userAnswersUuid) {
      uuid
      userId
      email
      submittedAt
      firstName
      consider {
        car
        bike
        bicycle
        scooter
        home
        content
        mortgage
        life
        housekeeper
        health
        dental
        criticalIllness
        hospitalization
        personalAccident
        sports
        child
        family
        drone
        workAccidentSelfEmployeed
        workAccidentEmployee
        investment
        ppr
        thirdPartyProfessional
        pet
        thirdPartyPet
      }
      recommended {
        car
        bike
        bicycle
        scooter
        home
        content
        mortgage
        life
        housekeeper
        health
        dental
        criticalIllness
        hospitalization
        personalAccident
        sports
        child
        family
        drone
        workAccidentSelfEmployeed
        workAccidentEmployee
        investment
        ppr
        thirdPartyProfessional
        pet
        thirdPartyPet
      }
      mandatory {
        car
        bike
        bicycle
        scooter
        home
        content
        mortgage
        life
        housekeeper
        health
        dental
        criticalIllness
        hospitalization
        personalAccident
        sports
        child
        family
        drone
        workAccidentSelfEmployeed
        workAccidentEmployee
        investment
        ppr
        thirdPartyProfessional
        pet
        thirdPartyPet
      }
    }
  }
`;

export const GET_ADVISOR_ANSWERS = gql`
  query getAdvisorAnswersPublic($userId: String, $userAnswerId: String!) {
    getAdvisorAnswersPublic(userId: $userId, userAnswerId: $userAnswerId) {
      uuid
      key
      partnerNif
      customerNif
      questions {
        uuid
        key
        question
        answer
      }
    }
  }
`;
export const ASSIGN_ADVISOR = gql`
  mutation assignAdvisorAnswersPublic(
    $userAnswerId: String
    $email: String
    $firstName: String
    $companyId: String
    $managerId: String
    $partnerConsents: PartnerCustomerConsentsInput
    $partnerNif: String
    $customerNif: String
    $name: String
    $phone: String
  ) {
    assignAdvisorAnswersPublic(
      input: {
        userAnswerId: $userAnswerId
        email: $email
        firstName: $firstName
        companyId: $companyId
        managerId: $managerId
        partnerConsents: $partnerConsents
        partnerNif: $partnerNif
        customerNif: $customerNif
        phone: $phone
        name: $name
      }
    ) {
      userAnswerId
      phone
    }
  }
`;
