import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router";
import Footer from "../footer";
import SideBar from "../sidebar";
import { useSelector } from "react-redux";

const AuthRoute = (props: any) => {
  const history = useHistory();
  const { path, title, hideSideBar, isContainer } = props;
  const isAuthenticated = useSelector((state: any) => state?.isAuthenticated);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push("/entrar");
    }
  }, [isAuthenticated]);

  return (
    <div className="main-container" style={{ padding: hideSideBar && 0 }}>
      {!hideSideBar && <SideBar />}
      {isAuthenticated ? (
        <div style={{ minHeight: "1100px", paddingBottom: "4rem" }}>
          <div style={{ paddingBottom: "100px" }}>
            <div className={isContainer ? "container" : "content-block"}>
              <Route {...props} />
            </div>
          </div>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/entrar",
            state: { from: path },
          }}
        />
      )}
      <Footer />
    </div>
  );
};

export default AuthRoute;
