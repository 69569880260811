import React, { useEffect } from "react";
import { Steps } from "antd";
import { useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router";
import logoImg from "../../images/mudeyPurpleProYellow1.png";
import NewFooter from "../footer";
import "./index.less";

const PublicRoutes = (props: any) => {
  const { isContainer, showHeader, customFooter } = props;
  const partnerSettingsLogo = useSelector(
    (state: any) => state?.partnerSettings?.logo
  );
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = useSelector((state: any) => state?.isAuthenticated);
  const isConfigApplied = useSelector((state: any) => state?.isConfigApplied);
  const partnerStatus = useSelector((state: any) => state?.userData?.verified);

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname == "/entrar" || location.pathname == "/registar") {
        if (partnerStatus == "VERIFIED") {
          return history.push("/dashboard");
        } else if (partnerStatus == "CREATED" || partnerStatus == null) {
          return history.push("/formulario-adesao");
        }
      }
    }
  }, [isAuthenticated]);
  const renderSteps = () => {
    const { pathname } = location;
    let step = -1;

    if (pathname.indexOf("/simulacao") > -1) step = 0;
    else if (
      pathname.indexOf("/resultados/pacotes") > -1 ||
      pathname.indexOf("/resultados/comparar") > -1
    )
      step = 1;
    else if (
      pathname.indexOf("/compra/resumo") > -1 ||
      pathname.indexOf("/resultados/summary") > -1
    )
      step = 2;

    return step >= 0 ? (
      <Steps current={step} progressDot size="small">
        <Steps.Step title="Questionário" />
        <Steps.Step title="Resultados" />
        <Steps.Step title="Compra" />
      </Steps>
    ) : (
      <div />
    );
  };

  return (
    <>
      <div
        className="main-container public-route"
        style={{ padding: 0, backgroundColor: isConfigApplied ? "white" : "" }}
      >
        <div
          style={{
            minHeight: isContainer && "1100px",
            paddingBottom: isContainer && "4rem",
          }}
        >
          {showHeader && (
            <header className="header" style={{ marginBottom: "10px" }}>
              <div className="container">
                <div className="header-wrapper">
                  {/* <a onClick={() => history.push(`/entrar`)}> */}
                  {Boolean(partnerSettingsLogo) ? (
                    <img
                      className="partner-logo"
                      alt="partner"
                      src={partnerSettingsLogo}
                      title="Partner"
                      onClick={() => {
                        if (isAuthenticated) history.push(`/entrar`);
                      }}
                    />
                  ) : (
                    <img
                      alt="Mud23ey"
                      src={logoImg}
                      title="Mudey"
                      onClick={() => {
                        if (isAuthenticated) history.push(`/entrar`);
                      }}
                    />
                  )}
                  {/* </a> */}
                  {renderSteps()}
                </div>
              </div>
            </header>
          )}
          <div style={{ paddingBottom: isContainer && "100px" }}>
            <div className={isContainer ? "container" : ""}>
              <Route {...props} />
            </div>
          </div>
        </div>
        {isContainer && <NewFooter customFooter={customFooter} />}
      </div>
    </>
  );
};

export default PublicRoutes;
