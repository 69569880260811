import React, { useEffect, useState, useCallback } from "react";
import Header from "~components/header";
import SingleCheckbox from "~components/Inputs/checkbox";
import { apiErrorHandler } from "~utils/apiErrorHandler";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Buttons } from "~components/buttons";
import { validationSchemaSectionOne } from "./utils/validationSchema";
import { useHistory } from "react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UPDATE_SUBSCRIPTION } from "../../graphQL/mutations";
import { GET_SUBSCRIPTION } from "../../graphQL/queries";
import { Spinner } from "~components/spinner";
import { convertPricetoFormat } from "~utils/priceFormatter";
import "../../resources/less/forms.less";
import "./index.less";

export default function PricingDetails() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isTouchedModule2, setIsTouchedModule2] = useState(false);
  const [isTouchedModule3, setIsTouchedModule3] = useState(false);
  const nif = useSelector((state: any) => state?.userData?.nif);
  const partnerStatus = useSelector((state: any) => state?.userData?.verified);
  const isVerifiedAccount = useSelector(
    (state: any) => state?.userData?.verified == "VERIFIED"
  );
  const formik = useFormik({
    initialValues: {
      module1: true,
      module2: false,
      module3: false,
      total: 20,
      nif,
    },
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const subscriptionPlan = [];
      if (values.module1) {
        subscriptionPlan.push("BASE");
      }
      if (values.module2) {
        subscriptionPlan.push("CROSS_SELL");
      }
      if (values.module3) {
        subscriptionPlan.push("BRANDING");
      }
      const newValues = {
        nif: nif,
        input: {
          subscriptionMonthlyValue: values.total,
          subscriptionPlan: subscriptionPlan,
        },
      };
      try {
        const { data } = await updateSubscription({
          variables: newValues,
        });
        if (data) {
          if (partnerStatus == "VERIFIED") {
            return history.push("/configuracoes");
          } else {
            return history.push("/formulário-payment");
          }
          // history.push("/formulário-payment");
        }
      } catch (error) {
        apiErrorHandler(error);
      }
    },
  });
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);

  const [getSubscription] = useLazyQuery(GET_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    onError: useCallback((error: any) => {
      apiErrorHandler(error);
      setIsLoading(false);
    }, []),
    onCompleted: useCallback(({ getPartnerCurrentSubscription }) => {
      formik.setFieldValue(
        "total",
        [null, 0].includes(
          getPartnerCurrentSubscription.subscriptionMonthlyValue
        )
          ? 20
          : getPartnerCurrentSubscription?.subscriptionMonthlyValue
      );
      formik.setFieldValue(
        "module2",
        getPartnerCurrentSubscription.subscriptionPlan?.includes("CROSS_SELL")
          ? true
          : false
      );
      formik.setFieldValue(
        "module3",
        getPartnerCurrentSubscription.subscriptionPlan?.includes("BRANDING")
          ? true
          : false
      );
      setIsLoading(false);
    }, []),
  });

  useEffect(() => {
    if (nif) {
      getSubscription({
        variables: {
          nif,
        },
      });
    }
  }, [nif]);

  const handleCheckboxChangeModule2 = () => {
    setIsTouchedModule2(true);
  };

  const handleCheckboxChangeModule3 = () => {
    setIsTouchedModule3(true);
  };
  return (
    <section id="pricing-details">
      <Header
        title="Subscrição"
        backButtonText={!isVerifiedAccount ? "<- Voltar atrás" : null}
        backButtonClick={() =>
          !isVerifiedAccount ? history.push(`/formulario-adesao`) : null
        }
      />
      {isLoading && <Spinner />}
      <form onSubmit={formik.handleSubmit}>
        <div className="pricing-selection-box">
          <div className="heading">
            <p className="sub-heading">Plano de Subscrição</p>
            <p className="sub-description">
              Seleciona quais os módulos que pretendes aderir.{" "}
            </p>
            <p className="sub-description">
              Nota: podes adicionar ou retirar os módulos adicionais a qualquer
              altura.
            </p>
          </div>
          <div className="desktop-version">
            <div className="module-info">
              <div className="column-description">
                <p className="module-name">Módulo Base</p>
                <ul>
                  <div className="wrapper">
                    <li>Simuladores e contratação digital </li>
                    <div />
                  </div>
                  <div className="wrapper">
                    <li>Acesso a +15 seguradoras/mês</li>
                    <div />
                  </div>
                  <div className="wrapper">
                    <li>Gestão leads</li>
                    <div />
                  </div>
                  <div className="wrapper">
                    <li>Gestão de apólices e de recibos</li>
                    <div />
                  </div>
                  <div className="wrapper">
                    <li>Dashboard de negócios</li>
                    <div />
                  </div>

                  <div className="wrapper">
                    <li>Suporte contínuo</li>
                    <div />
                  </div>
                </ul>
              </div>
              <div className="column-pricing">
                <p className="module-name" style={{ textAlign: "center" }}>
                  Preço*
                </p>
                <div className="wrapper">
                  <p>
                    <strike>
                      {convertPricetoFormat(20, false, "", 2, 2)} €/mês
                    </strike>
                  </p>
                </div>
                <div className="wrapper">
                  <p>Incluído</p>
                </div>
                <div className="wrapper">
                  <p>Incluído</p>
                </div>
                <div className="wrapper">
                  <p>Incluído</p>
                </div>

                <div className="wrapper">
                  <p>Incluído</p>
                </div>
                <div className="wrapper">
                  <p>Gratuito</p>
                </div>
              </div>
              <div className="subscribe-package">
                <p className="module-name">Subscrever</p>
                <div className="subscribe-content">
                  <SingleCheckbox
                    name="module1"
                    labelText=""
                    disabled
                    formik={formik}
                    className="label-text"
                    fontFamily="light"
                    mainDivStyle={{ marginBottom: 10 }}
                  />
                </div>
              </div>
            </div>

            <div className="module-info">
              <div className="column-description">
                <p className="module-name">Módulo de Venda Cruzada</p>
                <ul>
                  <div className="wrapper">
                    <li>Diagnóstico de Seguros </li>

                    <div />
                  </div>
                  <div
                    className="wrapper"
                    style={{ margin: "10px 0", height: "unset" }}
                  >
                    <i>
                      {isTouchedModule2 && partnerStatus != "CREATED" && (
                        <p>
                          {formik.values.module2
                            ? "O módulo ficará disponível imediatamente, e será cobrado o valor total por débito direto"
                            : "O módulo deixará de estar disponível imediatamente. No entanto, o valor do plano de subscrição será atualizado no próximo débito direto."}
                        </p>
                      )}
                    </i>
                  </div>
                </ul>
              </div>
              <div className="column-pricing">
                <p className="module-name" style={{ visibility: "hidden" }}>
                  Preço*
                </p>
                <div className="wrapper">
                  <p>{convertPricetoFormat(10, false, "", 2, 2)} €/mês</p>
                </div>
              </div>
              <div className="subscribe-package">
                <p className="module-name" style={{ visibility: "hidden" }}>
                  Subscrever
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SingleCheckbox
                    name="module2"
                    labelText=""
                    formik={formik}
                    className="label-text"
                    fontFamily="light"
                    customSize
                    mainDivStyle={{ marginTop: 0 }}
                    customOnChange={(e) => {
                      handleCheckboxChangeModule2();
                      if (e.target.checked) {
                        formik.setFieldValue(
                          "total",
                          Number(formik.values.total) + 10
                        );
                        formik.setFieldValue("module2", e.target.checked);
                      } else {
                        formik.setFieldValue(
                          "total",
                          Number(formik.values.total) - 10
                        );
                        formik.setFieldValue("module2", false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="module-info">
              <div className="column-description">
                <p className="module-name">Módulo de Marca</p>
                <ul>
                  <div className="wrapper">
                    <li>Customizar simuladores e módulos adicionais </li>
                    <div />
                  </div>
                  <div
                    className="wrapper"
                    style={{ margin: "10px 0", height: "unset" }}
                  >
                    <i>
                      {isTouchedModule3 && partnerStatus != "CREATED" && (
                        <p>
                          {formik.values.module3
                            ? "O módulo ficará disponível imediatamente, e será cobrado o valor total por débito direto"
                            : "O módulo deixará de estar disponível imediatamente. No entanto, o valor do plano de subscrição será atualizado no próximo débito direto."}
                        </p>
                      )}
                    </i>
                  </div>
                </ul>
              </div>
              <div className="column-pricing">
                <p className="module-name" style={{ visibility: "hidden" }}>
                  Preço*
                </p>
                <div className="wrapper">
                  <p>{convertPricetoFormat(75, false, "", 2, 2)} €/mês</p>
                </div>
              </div>
              <div className="subscribe-package">
                <p className="module-name" style={{ visibility: "hidden" }}>
                  Subscrever
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SingleCheckbox
                    name="module3"
                    labelText=""
                    formik={formik}
                    className="label-text"
                    fontFamily="light"
                    mainDivStyle={{ marginTop: 0 }}
                    customOnChange={(e) => {
                      handleCheckboxChangeModule3();
                      if (e.target.checked) {
                        formik.setFieldValue(
                          "total",
                          Number(formik.values.total) + 75
                        );
                        formik.setFieldValue("module3", e.target.checked);
                      } else {
                        formik.setFieldValue("module3", false);
                        formik.setFieldValue(
                          "total",
                          Number(formik.values.total) - 75
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="module-info">
              <div className="column-description">
                <p className="module-name">TOTAL</p>
                <ul>
                  <div className="wrapper">
                    <li> </li>
                    <div style={{ visibility: "hidden" }} />
                  </div>
                </ul>
              </div>
              <div className="column-pricing">
                <div
                  className="wrapper"
                  style={{
                    padding: "10px",
                    borderTop: "1px solid #895d85",
                    borderBottom: "3px double #895d85",
                  }}
                >
                  <p>{`${convertPricetoFormat(
                    Number(formik.values.total) - 20,
                    false,
                    "",
                    2,
                    2
                  )} €/mês`}</p>
                </div>
              </div>
              <div className="subscribe-package">
                <p className="module-name" style={{ visibility: "hidden" }}>
                  Subscrever
                </p>
              </div>
            </div>
            <p style={{ fontSize: "10px" }}>
              * Acresce IVA à taxa legal em vigor
            </p>
          </div>

          <div className="mobile-version">
            <div className="mobile-container">
              <SingleCheckbox
                name="module1"
                labelText="Subscrever"
                disabled
                formik={formik}
                className="label-text"
                fontFamily="light"
                mainDivStyle={{ marginBottom: 0 }}
                alignItems="center"
              />
              <div className="mobile-module">
                <div className="modules-mobile-heading">
                  <p className="name" style={{ fontWeight: "bold" }}>
                    Módulo Base
                  </p>
                  <p className="price" style={{ fontWeight: "bold" }}>
                    Preço*
                  </p>
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Simuladores e contratação digital </p>
                  <p className="price">
                    <strike>
                      {convertPricetoFormat(20, false, "", 2, 2)} €/mês
                    </strike>
                  </p>
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Acesso a +15 seguradoras/mês</p>
                  <p className="price">Incluído</p>
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Gestão leads</p>
                  <p className="price">Incluído</p>
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Gestão de apólices e de recibos</p>
                  <p className="price">Incluído</p>
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Dashboard de negócios</p>
                  <p className="price">Incluído</p>
                </div>

                <div className="modules-mobile-heading">
                  <p className="name">Suporte contínuo</p>
                  <p className="price">Gratuito</p>
                </div>
              </div>

              <SingleCheckbox
                name="module2"
                labelText="Subscrever"
                formik={formik}
                className="label-text"
                fontFamily="light"
                alignItems="center"
                mainDivStyle={{ marginBottom: 0 }}
                customOnChange={(e) => {
                  handleCheckboxChangeModule2();
                  if (e.target.checked) {
                    formik.setFieldValue(
                      "total",
                      Number(formik.values.total) + 10
                    );
                    formik.setFieldValue("module2", e.target.checked);
                  } else {
                    formik.setFieldValue("module2", false);
                    formik.setFieldValue(
                      "total",
                      Number(formik.values.total) - 10
                    );
                  }
                }}
              />
              <div className="mobile-module">
                <div className="modules-mobile-heading">
                  <p className="name" style={{ fontWeight: "bold" }}>
                    Módulo de Venda Cruzada
                  </p>
                  <p className="price" style={{ fontWeight: "bold" }} />
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">Diagnóstico de Seguros </p>
                  <p className="price">
                    {convertPricetoFormat(10, false, "", 2, 2)} €/mês
                  </p>
                </div>
                <div className="modules-mobile-heading">
                  <i>
                    {isTouchedModule2 && (
                      <p>
                        {formik.values.module2
                          ? "O módulo ficará disponível imediatamente, e será cobrado o valor total por débito direto"
                          : "O módulo deixará de estar disponível imediatamente. No entanto, o valor do plano de subscrição será atualizado no próximo débito direto."}
                      </p>
                    )}
                  </i>
                </div>
              </div>

              <SingleCheckbox
                name="module3"
                labelText="Subscrever"
                alignItems="center"
                formik={formik}
                className="label-text"
                fontFamily="light"
                mainDivStyle={{ marginBottom: 0 }}
                customOnChange={(e) => {
                  handleCheckboxChangeModule3();
                  if (e.target.checked) {
                    formik.setFieldValue(
                      "total",
                      Number(formik.values.total) + 75
                    );
                    formik.setFieldValue("module3", e.target.checked);
                  } else {
                    formik.setFieldValue("module3", false);
                    formik.setFieldValue(
                      "total",
                      Number(formik.values.total) - 75
                    );
                  }
                }}
              />
              <div className="mobile-module">
                <div className="modules-mobile-heading">
                  <p className="name" style={{ fontWeight: "bold" }}>
                    Módulo de Marca
                  </p>
                  <p className="price" style={{ fontWeight: "bold" }} />
                </div>
                <div className="modules-mobile-heading">
                  <p className="name">
                    Customizar simuladores e módulos adicionais{" "}
                  </p>
                  <p className="price">
                    {convertPricetoFormat(75, false, "", 2, 2)} €/mês
                  </p>
                </div>
                <div className="modules-mobile-heading">
                  <i>
                    {isTouchedModule3 && (
                      <p>
                        {formik.values.module3
                          ? "O módulo ficará disponível imediatamente, e será cobrado o valor total por débito direto"
                          : "O módulo deixará de estar disponível imediatamente. No entanto, o valor do plano de subscrição será atualizado no próximo débito direto."}
                      </p>
                    )}
                  </i>
                </div>
              </div>

              <div className="mobile-module">
                <div className="modules-mobile-heading">
                  <p className="name" style={{ fontWeight: "bold" }}>
                    TOTAL
                  </p>
                  <p className="price">{`${convertPricetoFormat(
                    Number(formik.values.total) - 20,
                    false,
                    "",
                    2,
                    2
                  )} €/mês`}</p>
                </div>
              </div>
              <p style={{ fontSize: "10px" }}>
                * Acresce IVA à taxa legal em vigor
              </p>
            </div>
          </div>
          <div className="btn-container">
            <Buttons type="submit" text=" Continuar" width="120px" />
          </div>
        </div>
      </form>
    </section>
  );
}
