import React from "react";
import { NavLink } from "react-router-dom";
import useUserRole from "../../hooks/useUserRole";
import { useSelector } from "react-redux";

export const SidebarItem = ({
  to,
  icon,
  text,
  mobileMenu,
  setMobileMenu,
  userVerified,
  disabledSidebarBtn,
}: any) => {
  const { isViewer, isMember } = useUserRole();

  const partnershipType = useSelector((state: any) => {
    return state?.partnerFormDetails?.partnershipType || state?.userData?.partnershipType
  });

  const viewerBlockedList = ["Cotar seguros", "Dados", "Configurações"];
  const memberBlockedList = ["Dados", "Configurações"];
  const techBlockedList = ["Apólices", "Recibos", "Venda Cruzada"];

  const preventDefaultConditions = [
    memberBlockedList.includes(text) && isMember,
    viewerBlockedList.includes(text) && isViewer,
    techBlockedList.includes(text) && partnershipType === "TECH",
    !disabledSidebarBtn,
  ];

  const handleClick = (e) => {
    if (preventDefaultConditions.some((condition) => condition)) {
      e.preventDefault();
      return;
    }

    setMobileMenu(!mobileMenu);
    window.scrollTo(0, 0);
  };

  const handledisableStyle = () => {
    return preventDefaultConditions.some((condition) => condition) ? { cursor: "no-drop", opacity: 0.4 } : {}
  };

  return (
    <li className={text == "Cotar seguros" ? "comprar-online" : ""}>
      <NavLink
        style={handledisableStyle()}
        to={to}
        className={`${
          text == "Cotar seguros" ? "nav-links-active" : "nav-links-inactive"
        }  ${disabledSidebarBtn === false ? "disable-nav-links" : ""}`}
        activeClassName="nav-links-active"
        // onClick={(e) => {
        //   if (isMember && ["Dados", "Configurações"].includes(text))
        //     return e.preventDefault();
        //   if (
        //     ["Cotar seguros", "Dados", "Configurações"].includes(text) &&
        //     isViewer
        //   )
        //     return e.preventDefault();
        //   if (disabledSidebarBtn === false) return e.preventDefault();
        //   setMobileMenu(!mobileMenu);
        //   window.scrollTo(0, 0);
        // }}
        onClick={handleClick}
        aria-disabled={disabledSidebarBtn === false ? true : undefined}
        // aria-disabled={!disabledSidebarBtn}
      >
        <img alt="" src={icon} />
        <span style={{ fontFamily: "Brandon Grotesque Regular" }}>{text}</span>
      </NavLink>
    </li>
  );
};
