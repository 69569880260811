import React from "react";
import PropTypes from "prop-types";
import tickIcon from "../../../images/tick-icon.png";
import { InputWrapper } from "../InputWrapper";
import "../inputs.less";
import { useDispatch } from "react-redux";

const SingleCheckbox = (props: any) => {
  const {
    formik,
    name,
    labelText,
    isRenderObject,
    isCheckboxDisabled,
    customOnChange,
    alignItems,
    customSize,
    hyperlinkSource,
    pStyle
  } = props;
  const formValue = _.get(formik.values, name);
  return (
    <InputWrapper {...props}>
      <div
        className={
          window?.location?.href?.includes("viagem") ||
          window?.location?.href?.includes("venda") ||
          window?.location?.href?.includes("credito") ||
          window?.location?.href?.includes("carro")
            ? "checkbox-options-journey declaration-consents"
            : "checkbox-options declaration-consents"
        }
        style={{ alignItems: alignItems, ...pStyle}}
      >
        <label style={{ display: "flex", flexDirection: "row" ,marginRight: "10px" }}>
          <input
            type="checkbox"
            id="chkProdTomove"
            checked={formValue}
            width={20}
            height={20}
            disabled={isCheckboxDisabled}
            onChange={
              customOnChange
                ? customOnChange
                : (e: any) => {
                    if (name == "module1") return;
                    if (e.target.checked) {
                      formik.setFieldValue(name, e.target.checked);
                    } else {
                      formik.setFieldValue(name, false);
                    }
                  }
            }
          />
          <span
            className="check-box-effect"
            style={{ backgroundImage: `url(${tickIcon})`, cursor: isCheckboxDisabled ? "not-allowed" : "pointer" }}
          />
          <p
            className={props.className ? props.className : "label-text-large"}
            style={{ display: "inline-block",
              fontFamily: props.fontFamily == "light" ? "BG-Light" : "",
            }}
            {...(isRenderObject &&
            hyperlinkSource == "signup" &&
            name == "privacyConsent"
              ? {
                  dangerouslySetInnerHTML: {
                    __html: props.labelText
                      .replace(
                        /Política de Privacidade/g,
                        '<a class="hyperlink label-text" href="https://mudey.pt/politica-de-privacidade/" target="_blank">$&</a>'
                      )
                      .replace(
                        /Condições de Utilização/g,
                        '<a class="hyperlink label-tpext" href="https://mudey.pt/termos-de-utilizacao/" target="_blank">$&</a>'
                      ),
                  },
                }
              : isRenderObject &&
                hyperlinkSource == "signup" &&
                name == "usageConsent"
              ? {
                  dangerouslySetInnerHTML: {
                    __html: props.labelText
                      .replace(
                        /Política de Privacidade/g,
                        '<a class="hyperlink label-text" href="https://mudey.pt/politica-de-privacidade/" target="_blank">$&</a>'
                      )
                      .replace(
                        /Condições de Utilização/g,

                        '<a class="hyperlink label-text" href="https://mudey.pt/mudeypro/termos-de-utilizacao/" target="_blank">$&</a>'
                      ),
                  },
                }
              : isRenderObject && hyperlinkSource == "partnerDetails"
              ? {
                  dangerouslySetInnerHTML: {
                    __html: props.labelText.replace(
                      /Termos e Condições de Utilização da MudeyPro/g,
                      '<a class="hyperlink label-text" href="https://mudey.pt/mudeypro/termos-de-utilizacao/" target="_blank">$&</a>'
                    ),
                  },
                }
              : {
                  children: props.labelText,
                })}
          />
        </label>
      </div>
    </InputWrapper>
  );
};

SingleCheckbox.propTypes = {
  question: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.string,
  formik: PropTypes.any,
};

export default SingleCheckbox;
