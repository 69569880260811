import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import mudeyProLogo from "~images/mudeyPurpleProYellow.svg";
import costaDuarteLogo from "~images/Costa_Duarte.png";
import { useSelector } from "react-redux";

const useUserType = () => {
  const configData = useSelector((state: any) => state?.configData);
  const userData = useSelector((state: any) => state?.userData);

  let partnershipType;
  let partnerNif;

  if (userData) {
    partnerNif = userData?.nif;
    partnershipType = userData?.partnershipType;
  }
  if (configData) {
    partnerNif = partnerNif && !_.isEmpty(partnerNif) ? partnerNif : configData?.adminContact?.nif;
    partnershipType = partnershipType && !_.isEmpty(partnershipType) ? partnershipType : configData?.partnershipType;
  }
  return partnershipType;
};

export const getPartner = () => {
  const configData = useSelector((state: any) => state?.configData);
  const userData = useSelector((state: any) => state?.userData);

  let partnershipType;
  let partnerNif;

  if (configData) {
    partnerNif = configData?.adminContact?.nif;
    partnershipType = configData?.partnershipType;
  } else {
    partnerNif = userData?.nif;
    partnershipType = userData?.partnershipType;
  }

  if (partnerNif === '503976555') {
    return 'COSTA_DUARTE';
  }

  return 'MUDEY';
};

export const getLogo = () => {
  const logo = {
    MUDEY: mudeyProLogo,
    COSTA_DUARTE: costaDuarteLogo
  }[getPartner()];

  return logo;
};

export default useUserType;
