import React from "react";
import "./index.less";
export const Buttons = ({
  text,
  callParentFunction,
  className,
  style,
  type,
  isDisabled,
  width,
  fontSize,
}: any) => {
  return (
    <button
      type={type ? type : "submit"}
      className={className || "partner-button"}
      style={{ width, fontSize: fontSize || "14px" }}
      onClick={() => callParentFunction && callParentFunction()}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};
