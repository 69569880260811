import { gql } from "@apollo/client";

export const INSTA_UPDATE_ORDER_NEW = gql`
  mutation InstaOrderUpdate($input: OrderUpdateInput) {
    instaOrderUpdate(input: $input) {
      uuid
    }
  }
`;

export const INSTA_UPDATE_ORDER = gql`
  mutation instaOrderUpdate(
    $uuidOrder: ID!
    $insurer: PersonInput
    $insuredPersons: [PersonInput]
    $payment: PaymentInput
    $consents: ConsentsInput
    $beneficiaryType: BeneficiaryType
    $beneficiaryPersons: [PersonInput]
    $additionalAttributes: OrderAdditionalAttributesInput
    $insuranceStartDate: Date
    $companyId: String
  ) {
    instaOrderUpdate(
      input: {
        uuidOrder: $uuidOrder
        insurer: $insurer
        insuredPersons: $insuredPersons
        payment: $payment
        consents: $consents
        beneficiaryType: $beneficiaryType
        beneficiaryPersons: $beneficiaryPersons
        additionalAttributes: $additionalAttributes
        insuranceStartDate: $insuranceStartDate
        companyId: $companyId
      }
    ) {
      uuid
    }
  }
`;

export const ADD_INTERNAL_POLICY = gql`
  mutation addInternalPolicy($id: ID!, $code: String) {
    addInternalPolicy(id: $id, code: $code) {
      id
    }
  }
`;

export const ADD_INSTA_ORDER = gql`
  mutation InstaOrderAdd(
    $uuidJourney: ID
    $uuidPackage: ID
    $orderType: OrderType
    $price: String
    $uuidPrice: ID
    $companyId: String
  ) {
    instaOrderAdd(
      input: {
        uuidJourney: $uuidJourney
        uuidPackage: $uuidPackage
        orderType: $orderType
        price: $price
        uuidPrice: $uuidPrice
        companyId: $companyId
      }
    ) {
      uuid
      companyId
    }
  }
`;

export const ADD_PARTNER_CUSTOMER = gql`
  mutation AddPartnerCustomer($input: PartnerCustomerInput) {
    addPartnerCustomer(input: $input) {
      customerId
      customerName
      customerNif
      customerBirthdate
      customerAddress
      customerPostalCode
      customerEmail
      customerPhone
      customerReference
      customerNotes
      customerType
      customerStatus
      partnerNif
      createdAt
      updatedAt
      deletedAt
      customerGender
      customerNationality
    }
  }
`;

export const UPDATE_PARTNER_CUSTOMER = gql`
  mutation UpdatePartnerCustomer($input: PartnerCustomerInput) {
    updatePartnerCustomer(input: $input) {
      customerId
      customerName
      customerNif
      customerBirthdate
      customerAddress
      customerPostalCode
      customerEmail
      customerPhone
      customerReference
      customerNotes
      customerType
      customerStatus
      partnerNif
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const INSTA_ORDER_PAY_NEW = gql`
  mutation InstaOrderPay($input: OrderPaymentInput) {
    instaOrderPay(input: $input) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`;

export const INSTA_ORDER_PAY = gql`
  mutation instaOrderPay(
    $uuid: ID!
    $paymentPeriod: PaymentPeriodInput!
    $paymentType: PaymentType!
    $name: String!
    $email: String!
    $accountHolder: String
    $iban: String
    $isBankAccountHolder: Boolean
    $phone: String
    $phoneIndicative: String
    $paymentIntent: String
    $firstPaymentMethod: PaymentType
    $paymentDocument: PartnerFileDataInput
  ) {
    instaOrderPay(
      input: {
        uuid: $uuid
        paymentPeriod: $paymentPeriod
        paymentType: $paymentType
        name: $name
        email: $email
        accountHolder: $accountHolder
        iban: $iban
        isBankAccountHolder: $isBankAccountHolder
        phone: $phone
        phoneIndicative: $phoneIndicative
        paymentIntent: $paymentIntent
        firstPaymentMethod: $firstPaymentMethod
        paymentDocument: $paymentDocument
      }
    ) {
      type
      status
      url
      message
      value
      entity
      reference
      alias
      firstPaymentMethod
      extra {
        url
        redirect
        includesSign
      }
    }
  }
`;
export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdatePartnerSubscription(
    $nif: String!
    $input: PartnerSubscriptionInput
  ) {
    updatePartnerSubscription(nif: $nif, input: $input) {
      subscriptionPlan
      subscriptionMonthlyValue
    }
  }
`;

export const UPLOAD_PARTNER_FILE = gql`
  mutation UploadPartnerFiles($input: PartnerFilesInput) {
    uploadPartnerFiles(input: $input) {
      name
      url
      fileUuid
    }
  }
`;

export const UPADTE_PARTNER_PAYMENT = gql`
  mutation UpdatePartnerPayment($nif: String!, $input: PartnerPaymentInput) {
    updatePartnerPayment(nif: $nif, input: $input) {
      ibanMonthlyFee
      ibanCommissionPayment
      isIbanSame
      ibanCommissionPaymentPdf {
        name
        url
        fileUuid
      }
    }
  }
`;

export const PUB_CAR_PACKAGES = gql`
  mutation publishCarJourneyPackages($id: ID!, $providers: [String]) {
    publishCarJourneyPackages(input: { id: $id, providers: $providers }) {
      data {
        base {
          civilResponsability {
            description
            included
          }
          glass {
            description
            included
          }
          travelAssistance {
            description
            included
          }
          legalProtection {
            description
            included
          }
          driverProtection {
            description
            included
          }
          peopleTransported {
            description
            included
          }
          theft {
            description
            included
          }
          nature {
            description
            included
          }
          replacementVehicle {
            description
            included
          }
          shockCollisionRollover {
            description
            included
          }
          fireExplosionLightning {
            description
            included
          }
          malicousActs {
            description
            included
          }
        }
        carValue
        policyCost
        insuranceProviderId
        carPackageId
        productName
        provider {
          id
          address
          description
          logo
          name
          vat
        }
        recommended
        id
        uuid
        price
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
      }
      id
      totalCount
    }
  }
`;

export const INSTA_ADD_ORDER_CAR = gql`
  mutation instaOrderAdd(
    $uuidJourney: ID!
    $uuidPackage: ID
    $orderType: OrderType
    $price: String
  ) {
    instaOrderAdd(
      input: {
        uuidJourney: $uuidJourney
        uuidPackage: $uuidPackage
        orderType: $orderType
        price: $price
      }
    ) {
      uuid
    }
  }
`;

export const REQUEST_PARTNER_MESSAGE = gql`
  mutation requestPartnerMessage($input: RequestPartnerContact) {
    requestPartnerMessage(input: $input)
  }
`;

export const UPLOAD_FILE_NO_AUTH = gql`
  mutation uploadFileNoAuth($file: Upload!, $id: ID) {
    uploadFileNoAuth(file: $file, id: $id) {
      fileId
      name
      url
    }
  }
`;

export const UPDATE_PARTNER_DETAILS_FORM = gql`
  mutation UpdatePartnerDetailsForm($input: PartnerDetailsFormInput) {
    updatePartnerDetailsForm(input: $input) {
      sendEmailDocsAdmin
    }
  }
`;
